import { useState, useEffect } from 'react';
import { useQuery } from 'react-query';
import moment from 'moment';
import cx from 'classnames';
import { wellbeingListWeeks } from 'api/ScoresApi';
import Layout from 'components/common/Layout';
import Spinner from 'components/common/Spinner';
import { MobileExperience, Wellbeing, Profile } from 'components/common/dashboard';
import { EmployeeHighlightOfTheWeek, LeaderShip } from 'components/employee';
import { commentsToManager } from 'api/NotesApi';
import { leadershipMonthsList } from 'api/ScoresApi';
import { useLanguage } from 'utils';
import { useMediaQuery } from 'hooks';
import { sizes } from 'constants/devicesSize';
import { TableProfileView } from 'components/common/dashboard/TableProfileView';

const EmployeeDashboard = () => {
  const { isRtl } = useLanguage();
  const isTablet = useMediaQuery(sizes.tablet);
  const isLaptop = useMediaQuery(sizes.laptop);
  const [startDate, setStartDate] = useState();
  const [leaderShipDate, setLeaderShipDate] = useState();

  const { data: listOfMonths } = useQuery('leadershipListMonths', leadershipMonthsList, {
    onSuccess: data => {
      const months = data.results.map(item => new Date(item.month).getTime());
      if (months?.length >= 1) {
        const lastMonth = Math.max(...months);
        setLeaderShipDate(moment(lastMonth).format('YYYY-MM-DD'));
      }
    },
  });

  const { data: datalistWeeks, isLoading: isListWeeks } = useQuery('wellbeingListWeeks', () =>
    wellbeingListWeeks(moment(startDate).format('YYYY-MM-DD')),
  );

  const { data: managerTeamNotesData } = useQuery('commentsToManager', () =>
    commentsToManager({ limit: 3 }),
  );

  useEffect(() => {
    setStartDate(datalistWeeks?.results[0]?.week);
  }, [isListWeeks, leaderShipDate]);

  useEffect(() => {
    if (!leaderShipDate) {
      setLeaderShipDate(listOfMonths?.results[0]?.month);
    }
  }, [leaderShipDate]);

  if (isListWeeks) {
    return (
      <div className="flex items-center h-screen ">
        <Spinner />
      </div>
    );
  }

  return (
    <Layout direction="column" justify="between" className="flex md:flex-col lg:flex-row">
      {isTablet && <MobileExperience isRtl={isRtl} />}
      {isLaptop && <TableProfileView />}
      <div
        className={cx(
          'block md:hidden   lg:block  md:w-3/12 w-full md:sticky md:top-16',
          isRtl ? 'pl-2 md:pl-5 lg:pl-9' : 'pr-2 md:pr-5 lg:pr-9',
        )}
      >
        <Profile className="h-96" />
      </div>
      <div className="w-full lg:w-9/12">
        <EmployeeHighlightOfTheWeek startDate={startDate} leaderShipDate={leaderShipDate} />

        <Wellbeing
          datalistWeeks={datalistWeeks?.results?.map(w => w.week)}
          startDate={startDate}
          setStartDate={setStartDate}
        />

        <LeaderShip
          mangerNotes={managerTeamNotesData?.results}
          leaderShipDate={leaderShipDate}
          setLeaderShipDate={setLeaderShipDate}
          listOfMonths={listOfMonths}
        />
      </div>
    </Layout>
  );
};

export default EmployeeDashboard;
