import moment from 'moment';

export const unSkipEmptyWeeks = data => {
  if (data?.length) {
    data = data.map(obj => {
      return {
        date: obj.week,
        week: moment(moment(obj.week).format('MM-DD-YYYY'), 'MM-DD-YYYY').isoWeek(),
        value: obj.value,
      };
    });

    // sort the data before processing incase it's missed
    data = data.sort((r2, r1) => {
      if (r1.date < r2.date) {
        return -1;
      }
      if (r1.date > r2.date) {
        return 1;
      }
      return 0;
    });

    let missing_weeks = [];
    let previous_week = data[0].week;

    for (const point of data.slice(1)) {
      if (point.week !== previous_week - 1) {
        // collect the missing week end create an object with null Y
        const weeks = Array.from(Array(Math.max(0, previous_week - point.week - 1)).keys()).map(
          index => {
            const year = moment(point.date).format('YYYY');
            const date = moment(year).add(previous_week - index - 1, 'weeks');
            return {
              date: moment(date).format('YYYY-MM-DD'),
              week: moment(moment(date).format('MM-DD-YYYY'), 'MM-DD-YYYY').isoWeek(),
              value: null,
            };
          },
        );

        missing_weeks = missing_weeks.concat(weeks);
      }
      previous_week = point.week;
    }
    // merge the data with missing weeks and sort them based on X
    const newData = data.concat(missing_weeks);
    return newData.sort((r2, r1) => {
      if (r1.date < r2.date) {
        return -1;
      }
      if (r1.date > r2.date) {
        return 1;
      }
      return 0;
    });
  }
  return data;
};
