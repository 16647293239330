import { useState } from 'react';
import { useQuery } from 'react-query';
import cx from 'classnames';
import { useLanguage } from 'utils';
import { getListOfMangerComments } from 'api/NotesApi';
import { teamStats, usersStats } from 'api/BusinessApi';
import { Modal, useModal } from 'context/Modal';
import Layout from 'components/common/Layout';
import { Text } from 'components/service';
import {
  TeamNotes,
  GeneralAppStateSection,
  MainSourcesStress,
  MobileExperience,
} from 'components/common/dashboard';
import { useMediaQuery } from 'hooks';
import { HighlightOfTheWeekEvolutionSection } from 'components/hr';
import { SideBar } from 'components/Management';
import {
  EnrolledUsersDepartments,
  HrWellbeingSection,
  HrLeaderShipSection,
  Report as DashboardReport,
} from 'components/hr';
import { sizes } from 'constants/devicesSize';
import { useFetchWellbeingTeamListWeeks } from 'api/ScoresApi/Wellbeing';
import { useFetchLeadershipTeamListMonths } from 'api/ScoresApi/Leadership/teamLeadershipListMonths';
import { useTeams } from 'context/teams';

export const tabHeader = [
  {
    name: 'Score',
  },
  {
    name: 'Attributes',
  },
  {
    name: 'Evolution',
  },
];

const HrDashboard = () => {
  const { teamSelected } = useTeams();
  const { isRtl } = useLanguage();
  const modal = useModal();
  const isTablet = useMediaQuery(sizes.tablet);
  const [reportSelected, setReportSelected] = useState([]);
  const [activeTab, setActiveTab] = useState('Score');
  const [leaderShipActiveTab, switchLeaderShipTab] = useState('Score');
  const [startDateMonths, setStartDateMonths] = useState();
  const [startDate, setStartDate] = useState();
  const [query, setQuery] = useState({
    wellbeing: 'weeks',
    leaderShip: 'months',
  });
  const teams = teamSelected?.filter(team => team != '');

  const { data: dataWellbeingHrListWeeks } = useFetchWellbeingTeamListWeeks({
    onSuccess: listWeeks => {
      setStartDate(listWeeks?.length > 0 && listWeeks[listWeeks.length - 1]);
    },
  });

  const { data: hrLeaderShipMonthsData } = useFetchLeadershipTeamListMonths({
    onSuccess: listMonths => {
      setStartDateMonths(listMonths?.length >= 1 && listMonths[listMonths.length - 1]);
    },
  });

  const { data: listOfMangerComments } = useQuery(['ListOfMangerComments', teamSelected], () =>
    getListOfMangerComments({ limit: 3, teams }),
  );

  // teamStats
  const { data: teamStatsData } = useQuery(
    ['teamStats', teamSelected],
    () => teamStats({ teams }),
    { enabled: teamSelected?.length >= 0 ? true : false },
  );

  const { data: usersStatsData } = useQuery(
    ['usersStats', teamSelected],
    () => usersStats({ teams }),
    { enabled: teamSelected?.length >= 0 ? true : false },
  );

  const wellbeingParticipation = Math.round(
    (teamStatsData?.completed_wellbeing_surveys_count /
      teamStatsData?.all_wellbeing_surveys_count) *
      100,
  );

  const leaderShipParticipation = Math.round(
    (teamStatsData?.completed_leadership_surveys_count /
      teamStatsData?.all_leadership_surveys_count) *
      100,
  );

  return (
    <div>
      <Layout direction="row" justify="between">
        {isTablet && <MobileExperience isRtl={isRtl} />}
        <SideBar />
        <div className="w-full md:w-9/12">
          {dataWellbeingHrListWeeks?.length === 0 && <MessageNoData isRtl={isRtl} />}
          <GeneralAppStateSection
            marginRight={false}
            modal={modal}
            reportSelected={reportSelected}
            setReportSelected={setReportSelected}
            teamStatsData={teamStatsData}
            usersStatsData={usersStatsData}
            dataWellbeingManagerListWeeks={dataWellbeingHrListWeeks}
            startDateMonths={startDateMonths}
            setStartDateMonths={setStartDateMonths}
            startDate={startDate}
            setStartDate={setStartDate}
            wellbeingParticipationRate={wellbeingParticipation ? wellbeingParticipation : '-'}
            leadershipParticipationRate={leaderShipParticipation ? leaderShipParticipation : '-'}
          >
            {!isTablet && <EnrolledUsersDepartments />}
          </GeneralAppStateSection>
          {/* ------------------------------------ HighlightOfTheWeek---------------------------------  */}
          <HighlightOfTheWeekEvolutionSection />
          {/* ------------------------------------ Wellbeing Section ---------------------------------  */}
          <HrWellbeingSection
            setActiveTab={setActiveTab}
            participationRate={wellbeingParticipation}
            activeTab={activeTab}
            setStartDate={setStartDate}
            startDate={startDate}
            dataWellbeingHrListWeeks={dataWellbeingHrListWeeks}
            query={query}
            setQuery={setQuery}
          />
          {/* ------------------------------------ Leadership Section ---------------------------------  */}
          {hrLeaderShipMonthsData?.length === 0 && (
            <MessageNoData isRtl={isRtl} text="First_month_message_no_date" />
          )}
          <HrLeaderShipSection
            switchLeaderShipTab={switchLeaderShipTab}
            leaderShipActiveTab={leaderShipActiveTab}
            setStartDateMonths={setStartDateMonths}
            hrLeaderShipMonthsData={hrLeaderShipMonthsData}
            startDateMonths={startDateMonths}
            query={query}
            setQuery={setQuery}
            participationRate={leaderShipParticipation}
          />
          <div className="mt-20">
            <MainSourcesStress teamSelected={teamSelected} />
          </div>
          <TeamNotes data={listOfMangerComments?.results} />
        </div>
        <Modal id="DashboardReport" component={DashboardReport} width="w-full md:w-5/6" />
      </Layout>
    </div>
  );
};

export default HrDashboard;

const MessageNoData = ({ isRtl, text = 'first_week_message_no_date' }) => {
  return (
    <div
      className={cx(
        'flex flex-col md:flex-row items-center p-3 bg-white border-vieva-yellow-1 font-Inter font-medium',
        isRtl ? 'border-r-4 rounded-l-lg' : 'border-l-4 rounded-r-lg',
      )}
    >
      <Text full={false} className="text-vieva-darken-blue-2" value={text} />
    </div>
  );
};
