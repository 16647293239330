import PropTypes from 'prop-types';
import cx from 'classnames';

import {
  DateSelect,
  ParticipationRateLabel,
  Section,
  WellbeingLabel,
} from 'components/common/dashboard';
import { Text } from 'components/service';
import { sizes } from 'constants/devicesSize';
import { useMediaQuery } from 'hooks';
import { tabHeader } from 'pages/manager/Dashboard';
import { Score, WellbeingEvolution } from 'components/Management';

import WellbiengAttributes from '../WellbiengAttributes';
import { useLanguage } from 'utils';

const ManagerWellbeingSection = ({
  setActiveTab,
  participationRate,
  activeTab,
  teamWellbeingDataLoading,
  teamWellbeingDetailLoading,
  setStartDate,
  startDate,
  dataWellbeingManagerListWeeks,
  query,
  setQuery,
}) => {
  const { isRtl } = useLanguage();
  const isMobile = useMediaQuery(sizes.mobileXl);
  const tabs = isMobile ? tabHeader?.filter(item => item.name != 'Attributes') : tabHeader;
  return (
    <Section
      collapsable={true}
      className="relative my-10"
      right={<WellbeingLabel />}
      left={<ParticipationRateLabel rate={participationRate} title="Wellbeing_surveys" />}
      mb="mb-5"
    >
      <div>
        <ul className="flex mb-5 border-b">
          {tabs.map((_i, index) => (
            <li
              key={_i.name + index}
              className={cx(
                'py-1 px-5 cursor-pointer text-base to-vieva-gray-3 font-medium',
                activeTab === _i.name && 'border-b-4 border-vieva-orange-2',
              )}
              onClick={() => setActiveTab(_i.name)}
            >
              <Text value={_i.name} full={false} />
            </li>
          ))}
        </ul>
      </div>
      {activeTab !== 'Attributes' && (
        <div className={cx('w-full mb-8 md:w-2/6', isRtl ? 'md:ml-5' : 'md:mr-5')}>
          <DateSelect
            datalistWeeks={dataWellbeingManagerListWeeks}
            startDate={startDate}
            setStartDate={setStartDate}
            icon={true}
          />
        </div>
      )}
      {activeTab == 'Score' && <Score startDate={startDate} />}
      {activeTab == 'Attributes' && (
        <WellbiengAttributes
          lastWeekDate={dataWellbeingManagerListWeeks[0]}
          isLoading={teamWellbeingDataLoading || teamWellbeingDetailLoading}
          query={query}
          setQuery={setQuery}
        />
      )}
      <div className="flex flex-col justify-between md:flex-row">
        {activeTab == 'Evolution' && <WellbeingEvolution startDate={startDate} />}
      </div>
    </Section>
  );
};

ManagerWellbeingSection.propTypes = {
  setActiveTab: PropTypes.func,
  participationRate: PropTypes.number,
  activeTab: PropTypes.string,
  teamDataDetail: PropTypes.array,
  teamWellbeingDataLoading: PropTypes.bool,
  teamWellbeingDetailLoading: PropTypes.bool,
  setStartDate: PropTypes.func,
  startDate: PropTypes.func,
  teamData: PropTypes.array,
  dataWellbeingManagerListWeeks: PropTypes.array,
  query: PropTypes.string,
  setQuery: PropTypes.func,
  dataOrganization: PropTypes.array,
  isLoadingDataOrganization: PropTypes.bool,
};

export default ManagerWellbeingSection;
