import { client } from 'api/client';
import { useTeams } from 'context/teams';
import { useQuery } from 'react-query';

export const teamWeekApiCall = async params => {
  const teams = params?.teams?.join();
  const normal = '/wellbeing/v2/team_weeks';
  const withTeams = `${normal}?team_ids__in=${teams}`;
  const { data } = await client.get(params?.teams?.length >= 1 ? withTeams : normal);
  return data;
};

export function useFetchWellbeingTeamListWeeks(config = {}) {
  const { teamSelected } = useTeams();
  const results = useQuery(
    ['WellbeingTeamListWeeks', teamSelected],
    () => teamWeekApiCall({ teams: teamSelected }),
    {
      ...config,
      enabled: teamSelected?.length >= 0,
    },
  );

  const listOfWeeks = [].concat(results?.data)?.reverse();

  return {
    ...results,
    data: listOfWeeks,
  };
}
