import cx from 'classnames';
import { useUser } from 'context/user';
import {
  CoachingNotification,
  DateLabel,
  HighlightOfTheWeek,
  Section,
  WeeklyHighlight,
  WellbeingScore,
} from 'components/common/dashboard';
import { useLanguage } from 'utils';

import { useEmployeeHighlightOfTheWeek } from './useEmployeeHighlightOfTheWeek';

const EmployeeHighlightOfTheWeek = ({ startDate, leaderShipDate }) => {
  const { user } = useUser();
  const { isRtl } = useLanguage();

  const {
    datalistWeeks,
    leaderShip,
    HighlightDataOrganization,
    highlightData,
    isLoadingHighlightData,
    currentWeekData,
  } = useEmployeeHighlightOfTheWeek({ startDate, leaderShipDate });
  const score = highlightData?.scores[0]?.value || 0;
  const previous_score =
    (highlightData?.scores?.length > 1 && highlightData?.scores[1]?.value) || 0;

  return (
    <Section
      right={<HighlightOfTheWeek text="Highlight_of_the_week" />}
      left={<DateLabel startDate={datalistWeeks && datalistWeeks[0]} />}
      mb="mb-16"
    >
      {currentWeekData?.scores[0]?.value <= user.business.coaching_score && (
        <CoachingNotification className="mb-4" />
      )}
      <div className="flex flex-col items-center md:flex-row">
        <WellbeingScore
          score={score}
          previous_score={previous_score}
          dataOrganization={HighlightDataOrganization}
          className={cx('md:w-2/6 w-full md:h-96 mb-3 md:mb-0', isRtl ? 'md:ml-5' : 'md:mr-5')}
          isLoading={isLoadingHighlightData}
        />
        <div className="flex flex-col w-full md:w-4/6 h-96">
          <WeeklyHighlight
            wellbeing={highlightData}
            leaderShip={leaderShip && leaderShip}
            highlight="positive"
            className="mb-3"
            isLoading={isLoadingHighlightData}
          />
          <WeeklyHighlight
            wellbeing={highlightData}
            leaderShip={leaderShip && leaderShip}
            highlight="negative"
            isLoading={isLoadingHighlightData}
          />
        </div>
      </div>
    </Section>
  );
};

export default EmployeeHighlightOfTheWeek;
