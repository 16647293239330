import cx from 'classnames';
import { useState, useEffect, useMemo } from 'react';
import { ResponsiveLine } from '@nivo/line';

import { Text } from 'components/service';
import { ToggleButton } from 'components/kit';
import { LoadingBox } from 'components/common/Tables/LoadingBox';
import { useLanguage } from 'utils';
import { unSkipEmptyWeeks } from 'utils/unSkipEmptyWeeks';

import { ReactComponent as MoodIcon } from 'assets/mood-icon.svg';
import { ReactComponent as SleepIcon } from 'assets/sleep-icon.svg';
import { ReactComponent as WorkloadIcon } from 'assets/workload-icon.svg';
import { ReactComponent as EnergyIcon } from 'assets/energy-icon.svg';
import { ReactComponent as ArrowPositive } from 'assets/arrow-positive.svg';
import { ReactComponent as ArrowNegative } from 'assets/arrow-negative.svg';
import { ReactComponent as ArrowStable } from 'assets/arrow-stable.svg';
import { useEvolutionEmployee } from './useEvolutionEmployee';

export const EvolutionEmployee = ({ startDate }) => {
  const [isWithOrganization, setWithOrganization] = useState(false);
  const [chartData, setChartData] = useState([]);
  // const [data, setData] = useState([]);
  const [cardSelected, setCardSelected] = useState('moods');

  const { isRtl } = useLanguage();
  const {
    currentWeekData,
    isLoadingCurrentWeekData,
    dataOrganization,
    isLoadingDataOrganization,
    scoreData,
    displayData,
  } = useEvolutionEmployee({ startDate });

  const moods = [
    { id: 'mood', color: '#4575B7', data: scoreData(unSkipEmptyWeeks(currentWeekData?.moods)) },
  ];

  const shouldLoading = isLoadingCurrentWeekData || isLoadingDataOrganization;

  useEffect(() => {
    setChartData(moods);
  }, [shouldLoading]);

  const data = useMemo(
    () =>
      currentWeekData
        ? displayData({ cardSelected, dataOrganization, currentWeekData, isWithOrganization })
        : [],
    [cardSelected, dataOrganization, currentWeekData, isWithOrganization],
  );

  return (
    <>
      <div className={cx('w-full md:w-2/6 h-96', isRtl ? 'md:ml-5' : 'md:mr-5')}>
        {shouldLoading && !currentWeekData ? (
          <LoadingBox count={4} width={306} height={80} />
        ) : (
          <WellbeingCards
            data={currentWeekData}
            dataOrganization={dataOrganization}
            chartData={chartData}
            setChartData={setChartData}
            scoreData={scoreData}
            isWithOrganization={isWithOrganization}
            setWithOrganization={setWithOrganization}
            cardSelected={cardSelected}
            setCardSelected={setCardSelected}
            isLoading={shouldLoading}
            isRtl={isRtl}
          />
        )}
      </div>
      <div className="flex flex-col w-full mt-4 md:w-4/6 md:mt-0">
        {shouldLoading && !currentWeekData && data[0]?.data ? (
          <LoadingBox count={1} width={613} height={336} />
        ) : (
          <div className="bg-white shadow-md rounded-2xl border-vieva-blue-5">
            <div className="h-88">
              <ResponsiveLine
                data={data[0]?.data ? data : []}
                margin={{ top: 20, right: 20, bottom: 50, left: 40 }}
                xScale={{ type: 'point' }}
                yScale={{
                  type: 'linear',
                  min: '0',
                  max: '10',
                  stacked: false,
                  reverse: false,
                }}
                yFormat=">-.2f"
                curve="monotoneX"
                axisTop={null}
                axisRight={null}
                axisBottom={{
                  orient: 'bottom',
                  tickSize: 2,
                  tickPadding: 2,
                  tickRotation: isRtl ? 290 : 65,
                }}
                axisLeft={{
                  orient: 'left',
                  tickSize: 5,
                  tickPadding: 5,
                  tickRotation: 0,
                  legend: '',
                  legendOffset: -40,
                  legendPosition: 'middle',
                }}
                enableGridX={false}
                colors={isWithOrganization ? ['#E15100', '#4575B7'] : ['#4575B7']}
                pointBorderWidth={2}
                pointSize={8}
                pointBorderColor="#fff"
                pointLabelYOffset={-12}
                enableArea={true}
                enableSlices="x"
                useMesh={true}
                legends={[]}
                motionConfig="stiff"
              />
            </div>
            <div className="flex flex-col items-center justify-between p-2 mb-4 md:flex-row sm:px-10">
              <div className="flex flex-col items-center justify-between w-full mb-4 space-y-6 md:flex-row md:mb-0 md:space-y-0">
                <div className="flex">
                  <div className="flex items-center p-1 mr-2 bg-white border-t shadow-md text-vieva-blue-1 rounded-xl border-vieva-gray-7 sm:mr-4">
                    <div
                      className={cx('h-2 w-2 rounded bg-vieva-blue-1', isRtl ? 'ml-2' : 'mr-2')}
                    />
                    <Text className="text-sm" value="MyScore" />
                  </div>
                  {isWithOrganization && (
                    <div className="flex items-center p-1 bg-white border-t shadow-md text-vieva-orange-3 rounded-xl border-vieva-gray-7">
                      <div
                        className={cx('h-2 w-2 rounded bg-vieva-orange-3', isRtl ? 'ml-2' : 'mr-2')}
                      />
                      <Text className="text-sm" value="YourOrganization" />
                    </div>
                  )}
                </div>

                <div className="flex items-center">
                  <ToggleButton
                    variant="orange"
                    activeColor="bg-vieva-orange-4"
                    value={isWithOrganization}
                    onChange={() => setWithOrganization(!isWithOrganization)}
                  />
                  <Text className="text-sm text-vieva-gray-3" value="YourOrganization" />
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

const WellbeingCards = ({
  data,
  setChartData,
  scoreData,
  cardSelected,
  setCardSelected,
  isRtl,
}) => {
  return (
    <div className="flex flex-col space-y-2 md:space-y-5">
      {data &&
        Object.keys(data).map((card, i) => {
          switch (card) {
            case 'moods':
              return (
                <div
                  key={card + i}
                  className={cx(
                    'flex items-center justify-between h-20 rounded-2xl shadow-md px-4 cursor-pointer',
                    cardSelected === 'moods'
                      ? 'border-vieva-gray-7 bg-vieva-gray-5'
                      : 'border-vieva-blue-5 bg-white',
                  )}
                  onClick={() => {
                    setCardSelected(card);
                    setChartData([
                      {
                        id: 'mood',
                        color: '#4575B7',
                        data: scoreData(unSkipEmptyWeeks(data?.moods)),
                      },
                    ]);
                  }}
                >
                  <div className="flex items-center">
                    <MoodIcon />
                    <Text
                      value="Mood"
                      full={false}
                      className={cx('text-vieva-gray-1', isRtl ? 'mr-4' : 'ml-4')}
                    />
                  </div>
                  <div className="flex items-center font-semibold text-md">
                    <div
                      className={cx(
                        'text-vieva-darken-blue-1 font-semibold',
                        isRtl ? 'ml-4' : 'mr-4',
                      )}
                    >
                      {data?.moods[0]?.value ? data?.moods[0]?.value.toFixed(1) : '---'}
                      <span className="text-sm font-normal text-vieva-gray-4"> / 10</span>
                    </div>
                    <div>
                      {data?.moods[0]?.value > data?.moods[1]?.value ? (
                        <ArrowPositive />
                      ) : data?.moods[0]?.value < data?.moods[1]?.value ? (
                        <ArrowNegative />
                      ) : (
                        <ArrowStable />
                      )}
                    </div>
                  </div>
                </div>
              );
            case 'energies':
              return (
                <div
                  key={card + i}
                  className={cx(
                    'flex items-center justify-between h-20 rounded-2xl shadow-md px-4 cursor-pointer',
                    cardSelected === 'energies'
                      ? 'border-vieva-gray-7 bg-vieva-gray-5'
                      : 'border-vieva-blue-5 bg-white',
                  )}
                  onClick={() => {
                    setCardSelected(card);
                    setChartData([
                      {
                        id: 'energy',
                        color: '#4575B7',
                        data: scoreData(unSkipEmptyWeeks(data?.energies)),
                      },
                    ]);
                  }}
                >
                  <div className="flex items-center">
                    <EnergyIcon />
                    <Text
                      value="Energy"
                      full={false}
                      className={cx('text-vieva-gray-1', isRtl ? 'mr-4' : 'ml-4')}
                    />
                  </div>
                  <div className="flex items-center">
                    <div
                      className={cx(
                        'text-vieva-darken-blue-1 font-semibold',
                        isRtl ? 'ml-4' : 'mr-4',
                      )}
                    >
                      {data?.energies[0]?.value ? data?.energies[0]?.value.toFixed(1) : '---'}
                      <span className="text-sm font-normal text-vieva-gray-4"> / 10</span>
                    </div>
                    <div>
                      {data?.energies[0]?.value > data?.energies[1]?.value ? (
                        <ArrowPositive />
                      ) : data?.energies[0]?.value < data?.energies[1]?.value ? (
                        <ArrowNegative />
                      ) : (
                        <ArrowStable />
                      )}
                    </div>
                  </div>
                </div>
              );
            case 'sleeps':
              return (
                <div
                  key={card + i}
                  className={cx(
                    'flex items-center justify-between h-20 rounded-2xl shadow-md px-4 cursor-pointer',
                    cardSelected === 'sleeps'
                      ? 'border-vieva-gray-7 bg-vieva-gray-5'
                      : 'border-vieva-blue-5 bg-white',
                  )}
                  onClick={() => {
                    setCardSelected(card);
                    setChartData([
                      {
                        id: 'sleep',
                        color: '#4575B7',
                        data: scoreData(unSkipEmptyWeeks(data?.sleeps)),
                      },
                    ]);
                  }}
                >
                  <div className="flex items-center">
                    <SleepIcon />
                    <Text
                      value="Sleep"
                      full={false}
                      className={cx('text-vieva-gray-1', isRtl ? 'mr-4' : 'ml-4')}
                    />
                  </div>
                  <div className="flex items-center">
                    <div
                      className={cx(
                        'text-vieva-darken-blue-1 font-semibold',
                        isRtl ? 'ml-4' : 'mr-4',
                      )}
                    >
                      {data?.sleeps[0]?.value ? data?.sleeps[0]?.value.toFixed(1) : '---'}
                      <span className="text-sm font-normal text-vieva-gray-4"> / 10</span>
                    </div>
                    <div>
                      {data?.sleeps[0]?.value > data?.sleeps[1]?.value ? (
                        <ArrowPositive />
                      ) : data?.sleeps[0]?.value < data?.sleeps[1]?.value ? (
                        <ArrowNegative />
                      ) : (
                        <ArrowStable />
                      )}
                    </div>
                  </div>
                </div>
              );
            case 'workloads':
              return (
                <div
                  key={card + i}
                  className={cx(
                    'flex items-center justify-between h-20 rounded-2xl shadow-md px-4 cursor-pointer',
                    cardSelected === 'workloads'
                      ? 'border-vieva-gray-7 bg-vieva-gray-5'
                      : 'border-vieva-blue-5 bg-white',
                  )}
                  onClick={() => {
                    setCardSelected(card);
                    setChartData([
                      {
                        id: 'workload',
                        color: '#4575B7',
                        data: scoreData(unSkipEmptyWeeks(data?.workloads)),
                      },
                    ]);
                  }}
                >
                  <div className="flex items-center">
                    <WorkloadIcon />
                    <Text
                      value="Workload"
                      full={false}
                      className={cx('text-vieva-gray-1', isRtl ? 'mr-4' : 'ml-4')}
                    />
                  </div>
                  <div className="flex items-center">
                    <div
                      className={cx(
                        'text-vieva-darken-blue-1 font-semibold',
                        isRtl ? 'ml-4' : 'mr-4',
                      )}
                    >
                      <WorkloadIndex score={data?.workloads[0]?.value} />
                    </div>
                  </div>
                </div>
              );
            default:
              return '';
          }
        })}
    </div>
  );
};

const WorkloadIndex = ({ score }) => {
  if (score >= 8) {
    return <Text value="Too_heavy" />;
  } else if (score >= 6 && score <= 7.9) {
    return <Text value="Heavy" />;
  } else if (score > 4 && score <= 5.9) {
    return <Text value="Adequate" />;
  } else if (score <= 4) {
    return <Text value="Low" />;
  } else {
    return '---';
  }
};
