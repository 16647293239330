import { useEffect, useState, useRef } from 'react';
import { useFetchWellbeingTeam } from 'api/ScoresApi/Wellbeing/wellbeingTeam';
import { useFetchWellbeingTeamMembersDetail } from 'api/ScoresApi/Wellbeing/wellbeingTeamMemebersDetail';
import { useFetchWellbeingTeamListWeeks } from 'api/ScoresApi/Wellbeing/wellbeingTeamListWeeks';
import { LoadingBox } from 'components/common/Tables/LoadingBox';
import { Text } from 'components/service';
import { useTeams } from 'context/teams';
import moment from 'moment';
import { CartName } from './CartName';
import { Column } from './Column';
import SubList from './SubList';
import { Value } from './Value';
import { useFetchWellbeingTeamMembersStats } from 'api/ScoresApi/Wellbeing/wellbeingTeamMemebersStats';
import { DownloadComponent } from 'components/common/dashboard';

const WellbeingTableWithScoreDistribution = () => {
  const printRef = useRef();
  const { teamSelected } = useTeams();
  const [scoreDisruption, openScoreDistribution] = useState();
  const [subListOpen, openSubLis] = useState(false);

  const { data: listOfWeeks, isLoading: isLoadingListOfWeeks } = useFetchWellbeingTeamListWeeks();

  const customWeeksToFetch =
    listOfWeeks?.length > 12
      ? moment(listOfWeeks[0])?.subtract(12, 'week').format('YYYY-MM-DD')
      : null;

  const { data: teamData, isLoading: isLoadingTeamData } = useFetchWellbeingTeam({
    key: ['WellbeingTeam', teamSelected],
    params: {
      week__gte: customWeeksToFetch,
      teams: teamSelected,
    },
    config: {
      enabled: teamSelected?.length >= 0 && listOfWeeks ? true : false,
    },
  });

  const { data: teamMembersDetail, isLoading: isLoadingTeamMembersDetail } =
    useFetchWellbeingTeamMembersDetail({ customWeeksToFetch });

  const { data: teamMembersStats, isLoading: isLoadingTeamMembersStats } =
    useFetchWellbeingTeamMembersStats({
      week__gte: customWeeksToFetch,
    });

  const firstKey = teamData && Object.keys(teamData).length >= 1 && Object.keys(teamData)[0];

  const customDates =
    teamData &&
    teamData?.results &&
    unskipEmptyWeeks([
      ...new Array(teamData?.results?.length).fill().map((_, i) => ({
        date: teamData?.results[i]?.week,
        week: teamData?.results[i]?.week,
      })),
    ]).map(item => ({
      label: label(item.date),
      ...item,
    }));

  let scoress = {};

  teamData?.results?.map(item => {
    Object.keys(item)?.map(key => {
      scoress[key] = scoress[key]
        ? scoress[key]?.concat({ value: item[key], week: item.week })
        : [{ value: item[key], week: item.week }];
    });
  });

  const scores = Object.entries(scoress)?.map(item => ({
    name: item[0],
    values: item[1],
  }));

  const customScores = scores?.map(item => {
    const values = unskipEmptyWeeks(
      item?.values?.map(item => ({
        label: label(item.week),
        week: item.week,
        date: item.week,
        value: item.value,
      })),
    ).map(item => ({
      ...item,
      label: label(item.date),
      week: item.date,
    }));

    return { name: item.name, values };
  });

  const customParticipationRateHistory = unskipEmptyWeeks(teamData?.results)?.map(item => {
    return {
      label: label(item.date),
      ...item,
      value: item.participated_count?.toString() + '/' + item.members_count?.toString(),
    };
  });
  const customMentalHealths = unskipEmptyWeeks(teamData?.results)?.map(item => ({
    label: label(item.date),
    ...item,
    value: item.mental_health_count?.toString() + '/' + item.members_count?.toString(),
  }));

  const customScoreDistribution = teamMembersStats?.results?.map(item => ({
    label: label(item.week),
    date: item.week,
    ...item,
  }));

  const va = customScores && customScores[0]?.values?.find(item => item.label === scoreDisruption);

  const openedDisruption = customScoreDistribution?.find(item => item.date === va?.date);

  let subList = [];

  teamMembersDetail?.forEach(item => {
    item?.team_data?.forEach(itemd => {
      if (!subList?.find(item => item?.id === itemd?.user?.id)) {
        subList.push({
          name: itemd?.user.full_name,
          id: itemd?.user.id,
        });
      }
    });
  });

  // create a new empty object
  const TableData = {};

  // copy array elements to th object
  if (customScores) {
    for (let i = 0; i < customScores?.length; i++) {
      TableData[customScores[i]?.name] = customScores[i];
    }
  }

  useEffect(() => {
    if (customDates?.length >= 1 && customDates[0]?.label) {
      openScoreDistribution(customDates[0]?.label);
    }
  }, [firstKey]);

  const shouldLoading =
    isLoadingTeamData ||
    isLoadingTeamMembersDetail ||
    isLoadingListOfWeeks ||
    isLoadingTeamMembersStats;

  return (
    <>
      <DownloadComponent name="weeks" componentRef={printRef} />
      <div
        className="flex w-full mt-8 overflow-hidden overflow-x-scroll text-sm shadow-lg rounded-xl xl:overflow-x-visible "
        style={{ background: '#F3F7F9' }}
        ref={printRef}
      >
        <div className="flex flex-col w-full overflow-x-scroll " style={{ background: '#F3F7F9' }}>
          <div className="flex flex-row ">
            <div
              className="sticky left-0 z-40 flex items-center justify-center w-48 h-full bg-white "
              style={{ minWidth: 250, maxWidth: 250, zIndex: 30 }}
            />
            <div className="flex w-4/5">
              {!shouldLoading ? (
                customDates?.map(({ label }, index) => {
                  return (
                    <div
                      key={index + 'months'}
                      className="flex justify-center text-xs font-bold bg-white border-b h-14 border-vieva-gray-6 text-vieva-gray-1 font-Inter"
                    >
                      <>
                        <div
                          className="relative flex items-center justify-center w-16 text-center border-l cursor-pointer"
                          onClick={() => {
                            if (scoreDisruption === label) {
                              return openScoreDistribution(index);
                            }
                            openScoreDistribution(label);
                          }}
                        >
                          <span className="text-sm font-normal text-center">{label}</span>
                          {/* + icon */}
                          <span className="absolute bottom-1 right-1"> &rarr;</span>
                        </div>

                        {scoreDisruption === label && (
                          <div className="h-full w-52">
                            <Text
                              className="flex items-center justify-center w-full h-full text-center bg-gray-100"
                              value="Score_Distribution"
                            />
                          </div>
                        )}
                      </>
                    </div>
                  );
                })
              ) : (
                <div className="w-full bg-white h-14"></div>
              )}
            </div>
          </div>
          <div className="mb-3 border-b">
            <Column
              cartName="Participation"
              values={customParticipationRateHistory}
              scoreDisruption={scoreDisruption}
              scoreDisruptionValue={false}
              IconPlus={false}
              bgColor="#ffffff"
              isLoading={shouldLoading}
            />
            <Column
              cartName="Mental_health_risk"
              values={customMentalHealths}
              scoreDisruption={scoreDisruption}
              scoreDisruptionValue={false}
              bgColor="#ffffff"
              IconPlus={false}
              isLoading={shouldLoading}
            />
          </div>
          <Columnh
            name={TableData?.score?.name}
            cartValues={TableData?.score?.values}
            teamMembersDetail={teamMembersDetail}
            subListOpen={subListOpen}
            openSubLis={openSubLis}
            scoreDisruption={scoreDisruption}
            openedDisruption={openedDisruption}
            subList={subList}
            customDates={customDates}
            isLoading={shouldLoading}
          />

          <Columnh
            name={TableData?.workload?.name}
            cartValues={TableData?.workload?.values}
            teamMembersDetail={teamMembersDetail}
            subListOpen={subListOpen}
            openSubLis={openSubLis}
            scoreDisruption={scoreDisruption}
            openedDisruption={openedDisruption}
            subList={subList}
            customDates={customDates}
            isLoading={shouldLoading}
          />

          <Columnh
            name={TableData?.mood?.name}
            cartValues={TableData?.mood?.values}
            teamMembersDetail={teamMembersDetail}
            subListOpen={subListOpen}
            openSubLis={openSubLis}
            scoreDisruption={scoreDisruption}
            openedDisruption={openedDisruption}
            subList={subList}
            customDates={customDates}
            isLoading={shouldLoading}
          />

          {/* energies */}
          <Columnh
            name={TableData?.energy?.name}
            cartValues={TableData?.energy?.values}
            teamMembersDetail={teamMembersDetail}
            subListOpen={subListOpen}
            openSubLis={openSubLis}
            scoreDisruption={scoreDisruption}
            openedDisruption={openedDisruption}
            subList={subList}
            customDates={customDates}
            isLoading={shouldLoading}
          />
        </div>
      </div>
    </>
  );
};

const Columnh = ({
  name,
  cartValues,
  teamMembersDetail,
  openSubLis,
  subListOpen,
  scoreDisruption,
  openedDisruption,
  subList,
  customDates,
  isLoading,
}) => {
  return (
    <div
      style={{ background: '#F3F7F9' }}
      key={name + 'Value' + new Date()}
      className="flex flex-col"
    >
      <div className="flex-row w-full" style={{ background: '#F3F7F9' }}>
        <div className="flex items-center justify-start font-medium border-t h-14 border-vieva-gray-6 border-r-vieva-gray-7 text-vieva-gray-3">
          <CartName
            name={name}
            openSubLis={openSubLis}
            subListOpen={subListOpen}
            anonyms={teamMembersDetail?.is_anonymous}
            IconPlus={!!teamMembersDetail}
          />
          <div className="flex w-4/5 ">
            {isLoading ? (
              <LoadingBox className="w-11/12 mx-auto" count={1} width="100%" />
            ) : (
              cartValues?.map(({ label, value }, index) => {
                return (
                  <Value
                    key={value + label}
                    value={value}
                    month={label}
                    name={name}
                    index={index}
                    open={scoreDisruption}
                    teamMembersDetail={teamMembersDetail}
                    openedDisruptionValue={openedDisruption}
                  />
                );
              })
            )}
          </div>
        </div>
      </div>
      {/* Sub List */}
      {subListOpen === name &&
        subList?.map((member, key) => {
          return (
            <SubList
              key={key + 'key'}
              teamName={member?.name}
              teamId={member?.id}
              teamValues={teamMembersDetail}
              open={scoreDisruption}
              openedList={subListOpen}
              customDates={customDates}
            />
          );
        })}
    </div>
  );
};

export default WellbeingTableWithScoreDistribution;

const label = date =>
  `${moment(moment(date).startOf('week')).format('DD')}-${moment(moment(date).endOf('week')).format(
    'DD',
  )} ${moment(moment(date).endOf('week')).format('MMM')}`;

const unskipEmptyWeeks = data => {
  if (data?.length) {
    data = data.map(obj => {
      return {
        ...obj,
        date: obj.week,
        week: moment(moment(obj.week).format('MM-DD-YYYY'), 'MM-DD-YYYY').isoWeek(),
        value: obj.value,
      };
    });

    // sort the data before processing incase it's missed
    data = data.sort((r2, r1) => {
      if (r1.date < r2.date) {
        return -1;
      }
      if (r1.date > r2.date) {
        return 1;
      }
      return 0;
    });

    let missing_weeks = [];
    let previous_week = data[0].week;

    for (const point of data.slice(1)) {
      if (point.week !== previous_week - 1) {
        // collect the missing week end create an object with null Y
        const weeks = Array.from(Array(Math.max(0, previous_week - point.week - 1)).keys()).map(
          index => {
            const year = moment(point.date).format('YYYY');
            const date = moment(year).add(previous_week - index - 1, 'weeks');
            return {
              label: label(date),
              date: moment(date).format('YYYY-MM-DD'),
              week: moment(date).format('YYYY-MM-DD'),
              value: null,
            };
          },
        );

        missing_weeks = missing_weeks.concat(weeks);
      }
      previous_week = point.week;
    }
    // merge the data with missing weeks and sort them based on X
    const newData = data.concat(missing_weeks);

    return newData.sort((r2, r1) => {
      if (r1.date < r2.date) {
        return -1;
      }
      if (r1.date > r2.date) {
        return 1;
      }
      return 0;
    });
  }
  return data;
};
