import { client } from 'api/client';
import { useQuery } from 'react-query';

// /this wellbeingManager
export const well2ApiCall = async params => {
  const { data } = await client.get('/wellbeing/v2', {
    params: {
      team_ids__in: params?.teams?.join(),
      week__lte: params.week__lte,
      week__gte: params.week__gte,
      week: params.week,
    },
  });

  return data;
};

export function useFetchWellbeingTeam({ key, params, config }) {
  return useQuery(key, () => well2ApiCall(params), config);
}
