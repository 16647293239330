import { useRef } from 'react';
import { useQuery } from 'react-query';
import cx from 'classnames';
import { Text } from 'components/service';
import { leadershipAllTeamsPerfomance, leadershipTeamPerfomance } from 'api/ScoresApi';
import { Row } from 'components/common/Tables/Row';
import { TableLoader } from 'components/common/Tables/TableLoader';
import { useLanguage } from 'utils';
import { useTeams } from 'context/teams';
import { DownloadComponent } from 'components/common/dashboard';

const TableLeaderShipTeamPerformance = ({ selectedDate, includeSubTeams }) => {
  const printRef = useRef();
  const { teamSelected } = useTeams();
  const { isRtl } = useLanguage();
  const month = selectedDate && selectedDate !== 'All_Time' ? selectedDate : null;

  const { data: leadershipTeamPerformanceData, isLoading: isLoadingLeadershipTeamPerformanceData } =
    useQuery(
      month
        ? ['leadershipTeamPerformance', month, teamSelected, includeSubTeams]
        : ['leadershipTeamPerformance', teamSelected, includeSubTeams],
      () => leadershipTeamPerfomance({ startedDate: month, teams: teamSelected, includeSubTeams }),
      { enabled: teamSelected?.length >= 0 },
    );

  const {
    data: leadershipAllTeamsPerformanceData,
    isLoading: isLoadingLeadershipAllTeamsPerformanceData,
  } = useQuery(
    month ? ['leadershipAllTeamsPerformance', month] : 'leadershipAllTeamsPerfomance',
    () => leadershipAllTeamsPerfomance({ startedDate: month, teams: teamSelected }),
  );

  const data = leadershipTeamPerformanceData?.results || leadershipTeamPerformanceData;
  const Nav = data?.map(team => ({
    name: team?.team_name,
    id: team?.team_id,
  }));

  return (
    <>
      <DownloadComponent name="business units" componentRef={printRef} />
      <div ref={printRef} className="flex mt-8 overflow-hidden bg-white shadow-lg rounded-xl">
        <div className="sticky left-0 z-40 w-64 bg-white">
          <div className="border-b border-r h-14"></div>
          <div className="my-4">
            <div className="flex items-center justify-between pl-5 pr-5 font-medium border-t h-14 border-vieva-gray-6 border-r-vieva-gray-7 text-vieva-gray-3">
              <Text className={cx('text-sm', isRtl ? 'ml-4' : 'mr-4')} value="Participation" />
            </div>
            <div className="flex items-center justify-between pl-5 pr-5 font-medium border-t border-b h-14 border-vieva-gray-6 border-r-vieva-gray-7 text-vieva-gray-3">
              <Text className={cx('text-sm', isRtl ? 'ml-4' : 'mr-4')} value="Attrition_Risk" />
            </div>
          </div>

          {[
            {
              name: 'global_scores',
            },
            { name: 'autonomies' },
            {
              name: 'acknowledgements',
            },
            {
              name: 'commitments',
            },
            {
              name: 'ambitions',
            },
            {
              name: 'relationship_to_business',
            },
            {
              name: 'relationship_to_manager',
            },
          ].map(({ name }, index) => {
            return (
              <div
                key={index}
                className="flex items-center justify-between pl-5 pr-5 font-medium border-t border-r h-14 border-vieva-gray-6 border-r-vieva-gray-7 text-vieva-gray-3"
              >
                <Text className={cx('text-sm', isRtl ? 'ml-4' : 'mr-4')} value={name} />
              </div>
            );
          })}
        </div>
        {isLoadingLeadershipTeamPerformanceData || isLoadingLeadershipAllTeamsPerformanceData ? (
          <div className="w-full">
            <TableLoader horizontalCount={7} />
          </div>
        ) : (
          <div className="flex overflow-x-scroll bg-white ">
            <>
              {!includeSubTeams && (
                <AllColumn leadershipAllTeamsPerfomanceData={leadershipAllTeamsPerformanceData} />
              )}
              {Nav?.map((value, index) => (
                <div key={index + 'week'} className=" min-w-100">
                  <span className="flex items-center justify-center w-full p-2 px-5 text-xs font-bold text-center border-b border-r h-14 border-vieva-gray-6 text-vieva-gray-1 font-Inter">
                    {value?.name}
                  </span>
                  <div
                    style={{ hover: { boxShadow: '0px 2px 20px rgba(44, 110, 148, 0.17)' } }}
                    className="border border-transparent border-opacity-50 rounded-lg hover:shadow-2xl hover:border hover:border-gray-300 -z-50 min-w-100"
                  >
                    <div className="my-4">
                      {[
                        {
                          value: data.find(item => item?.team_id === value?.id)?.participation_rate,
                        },
                        {
                          value: data.find(item => item?.team_id === value?.id)?.mental_health,
                        },
                      ].map(({ value }, index) => (
                        <div
                          key={index}
                          className="flex items-center justify-center border-t h-14 -z-50"
                        >
                          <Row width="w-full" bgColor="bg-white" percentageValue value={value} />
                        </div>
                      ))}
                    </div>
                    {[
                      {
                        value: data.find(item => item?.team_id === value?.id)?.score,
                      },
                      {
                        value: data.find(item => item?.team_id === value?.id)?.autonomy,
                      },
                      {
                        value: data.find(item => item?.team_id === value?.id)?.acknowledgement,
                      },
                      {
                        value: data.find(item => item?.team_id === value?.id)?.commitment,
                      },
                      {
                        value: data.find(item => item?.team_id === value?.id)?.ambition,
                      },
                      {
                        value: data.find(item => item?.team_id === value?.id)
                          ?.relationship_to_business,
                      },
                      {
                        value: data.find(item => item?.team_id === value?.id)
                          ?.relationship_to_manager,
                      },
                    ].map(({ value }, index) => (
                      <div key={index} className="flex items-center justify-center h-14 -z-50">
                        <Row width="w-full" value={value} />
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </>
          </div>
        )}
      </div>
    </>
  );
};

const AllColumn = ({ leadershipAllTeamsPerfomanceData }) => {
  return (
    <div className="bg-white w-36">
      <Text
        value="All"
        full={false}
        className="flex items-center justify-center w-auto p-2 px-5 text-xs font-bold text-center border-b border-r h-14 border-vieva-gray-6 text-vieva-gray-1 font-Inter"
      />
      <div
        style={{ hover: { boxShadow: '0px 2px 10px rgba(44, 110, 148, 0.17)' } }}
        className="border border-transparent border-opacity-50 rounded-lg hover:shadow-2xl hover:border hover:border-gray-300 -z-50 min-w-100 "
      >
        <div className="my-4">
          {[
            {
              value: leadershipAllTeamsPerfomanceData?.participation_rate,
            },
            {
              value: leadershipAllTeamsPerfomanceData?.mental_health,
            },
          ].map(({ value }, index) => (
            <div key={index} className="flex items-center justify-center border-t h-14 -z-50">
              <Row width="w-full" bgColor="bg-white" percentageValue value={value} />
            </div>
          ))}
        </div>
        {[
          {
            value: leadershipAllTeamsPerfomanceData?.score,
          },
          {
            value: leadershipAllTeamsPerfomanceData?.autonomy,
          },
          {
            value: leadershipAllTeamsPerfomanceData?.acknowledgement,
          },
          {
            value: leadershipAllTeamsPerfomanceData?.commitment,
          },
          {
            value: leadershipAllTeamsPerfomanceData?.ambition,
          },
          {
            value: leadershipAllTeamsPerfomanceData?.relationship_to_business,
          },
          {
            value: leadershipAllTeamsPerfomanceData?.relationship_to_manager,
          },
        ].map(({ value }, index) => (
          <div key={index} className="flex items-center justify-center h-14 -z-50">
            <Row width="w-full" value={value} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default TableLeaderShipTeamPerformance;
