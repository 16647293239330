import { useQuery } from 'react-query';
import { useState } from 'react';
import cx from 'classnames';
import moment from 'moment';

import {
  ParticipationRateLabel,
  WellbeingLabel,
  Section,
  DateSelect,
} from 'components/common/dashboard';
import { Text } from 'components/service';
import { useLanguage } from 'utils';
import { wellbeing } from 'api/ScoresApi';
import { useUser } from 'context/user';

import { EvolutionEmployee } from '../EvolutionEmployee';
import Score from './score';

const Wellbeing = ({ datalistWeeks, startDate, setStartDate }) => {
  const { user } = useUser();

  const { isRtl } = useLanguage();
  const [activeTab, setActiveTab] = useState('Score');

  const { data: currentWeekData, isLoading: isLoadingCurrentWeekData } = useQuery(
    ['wellbeing', startDate],
    () =>
      wellbeing({
        week: moment(startDate).format('YYYY-MM-DD'),
      }),
    { enabled: startDate ? true : false },
  );

  const isLoading = isLoadingCurrentWeekData;

  const participationRate = Math.round(user?.wellbeing_participation_rate * 100);

  return (
    <Section
      className="my-10"
      right={<WellbeingLabel />}
      left={<ParticipationRateLabel rate={participationRate} title="Wellbeing_surveys" />}
    >
      <div>
        <ul className="flex my-5 border-b">
          {tabHeader.map((_i, index) => (
            <li
              key={_i.name + index}
              className={cx(
                'py-1 px-5 cursor-pointer text-base to-vieva-gray-3 font-medium',
                activeTab === _i.name && 'border-b-4 border-vieva-orange-2',
              )}
              onClick={() => setActiveTab(_i.name)}
            >
              <Text value={_i.name} full={false} />
            </li>
          ))}
        </ul>
      </div>
      <div className="flex flex-col">
        <div className="flex flex-col justify-between h-16 md:flex-row">
          <div className={cx('my-auto w-full md:w-2/6', isRtl ? 'md:ml-5' : 'md:mr-5')}>
            <DateSelect
              datalistWeeks={datalistWeeks}
              startDate={startDate}
              setStartDate={setStartDate}
              icon={true}
            />
          </div>
          <div className="w-4/6" />
        </div>
        <div className="flex flex-col justify-between md:flex-row">
          {activeTab == 'Score' && <Score wellbeing={currentWeekData} isLoading={isLoading} />}
          {activeTab == 'Evolution' && <EvolutionEmployee startDate={startDate} />}
        </div>
      </div>
    </Section>
  );
};
export default Wellbeing;

const tabHeader = [
  {
    name: 'Score',
  },
  {
    name: 'Evolution',
  },
];
