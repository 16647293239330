import { client } from 'api/client';
import { useQuery } from 'react-query';

const scoreHistApiCall = async params => {
  const { data } = await client.get(
    `/wellbeing/v2/score_history?week__gte=${params.week__gte}&team_ids__in=${params.teams_ids}`,
  );
  return data;
};

export function useFetchWellbeingScoreHistory({ key, params, config }) {
  return useQuery(key, () => scoreHistApiCall(params), config);
}
