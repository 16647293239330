import { useFetchWellbeingOrganization, useFetchWellbeingTeam } from 'api/ScoresApi/Wellbeing';
import { useTeams } from 'context/teams';
import moment from 'moment';
import { useMemo } from 'react';

export const useWellbeingEvolution = ({ startDate, cardSelected, isWithOrganization }) => {
  const { teamSelected } = useTeams();
  const { data: teamData, isLoading: teamWellbeingDataLoading } = useFetchWellbeingTeam({
    key: ['wellbeingManager', startDate, teamSelected],
    params: {
      teams: teamSelected,
      week__lte: startDate,
      week__gte: moment(startDate).subtract(13, 'weeks').format('YYYY-MM-DD'),
    },
    config: {
      enabled: teamSelected?.length >= 0 && startDate ? true : false,
    },
  });
  const wellbeingData = teamData?.results;

  const { data: dataOrganization, isLoading: isLoadingDataOrganization } =
    useFetchWellbeingOrganization({
      key: ['WellbeingOrganization', startDate],
      params: {
        week__lte: moment(startDate).format('YYYY-MM-DD'),
        week__gte: moment(startDate).subtract(13, 'weeks').format('YYYY-MM-DD'),
      },
      config: {
        enabled: startDate ? true : false,
      },
    });

  const scoreData = useMemo(
    () =>
      wellbeingData
        ? unskipEmptyWeeks(wellbeingData)?.map(score => {
            const scoreOrg = unskipEmptyWeeks(dataOrganization?.results)?.find(
              data => data.date == score?.date,
            );

            return {
              score: score[cardSelected],
              x: `${moment(moment(score?.date).startOf('week')).format('DD')}-${moment(
                moment(score?.date).endOf('week'),
              ).format('DD')} ${moment(moment(score?.date).endOf('week')).format('MMM')}`,

              participation_rate:
                score?.members_count === 0
                  ? 0
                  : (score?.participated_count / score?.members_count) * 10,

              participated_members: score?.participated_count,
              members_count: score?.members_count,

              organization:
                cardSelected && scoreOrg && cardSelected in scoreOrg ? scoreOrg[cardSelected] : [],
            };
          })
        : [],
    [cardSelected, isWithOrganization, wellbeingData, wellbeingData],
  );

  return {
    wellbeingData,
    dataOrganization,
    isLoadingDataOrganization,
    teamWellbeingDataLoading,
    scoreData,
  };
};

const unskipEmptyWeeks = values => {
  let data = [];
  if (values?.length) {
    data = values.map(obj => {
      return {
        ...obj,
        week: moment(moment(obj.week).format('MM-DD-YYYY'), 'MM-DD-YYYY').isoWeek(),
        date: obj.week,
        value: obj.value,
      };
    });

    // sort the data before processing incase it's missed
    data = data.sort((r2, r1) => {
      if (r1.date < r2.date) {
        return -1;
      }
      if (r1.date > r2.date) {
        return 1;
      }
      return 0;
    });

    let missing_weeks = [];
    let previous_week = data[0].week;

    for (const point of data) {
      if (point.week !== previous_week - 1) {
        // collect the missing week end create an object with null Y
        const weeks = Array.from(Array(Math.max(0, previous_week - point.week - 1)).keys()).map(
          index => {
            const year = moment(point.date).format('YYYY');
            const date = moment(year).add(previous_week - index - 1, 'weeks');
            return {
              date: moment(date).format('YYYY-MM-DD'),
              week: moment(moment(date).format('MM-DD-YYYY'), 'MM-DD-YYYY').isoWeek(),
              value: null,
            };
          },
        );

        missing_weeks = missing_weeks.concat(weeks);
      }
      previous_week = point.week;
    }

    // merge the data with missing weeks and sort them based on X
    const newData = data.concat(missing_weeks);
    return newData.sort((r2, r1) => {
      if (r1.date < r2.date) {
        return -1;
      }
      if (r1.date > r2.date) {
        return 1;
      }
      return 0;
    });
  }
  return data;
};
